import { createRouter, createWebHashHistory,createWebHistory} from 'vue-router'
import indexView from '@/views/index/index.vue'
import twoView from '@/views/20move/index.vue'
import newIndex from '@/views/newIndex/index.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component:twoView
  },
  {
    path: '/30gd',
    name: '30gd',
    component:indexView
  },
  {
    path: '/newIndex',
    name: 'newIndex',
    component:newIndex
  },
  // {
  //   path: '/authuser',
  //   name: 'authuser',
  //   component: () => import('@/views/authuser/authuser.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
