<template>
	<div class="all">
		<div class="imgbox">
			<img src="@/assets/bg2.png" alt="">
		</div>
		<div class="body">
			<div class="form" :style="`height: ${350*sum}px;`">
				<p>办理业务</p>
				<input type="text" :style="`margin-top: ${20*sum}px;`" class="input" v-model="from1" placeholder="请输入手机号" />
				<div class="row_sp" :style="`margin-top: ${20*sum}px;`">
					<input type="text" class="input" v-model="code" placeholder="请输入验证码" style="max-width: 60%;" />
					<div class="code" v-if="!sendType" @click="getCode">获取验证码</div>
					<div class="code null" v-else>{{num}}秒</div>
				</div>
				<div class="button" @click="Submit" :class="submitStop?'null':''" :style="`margin-top: ${20*sum}px;`">确认办理</div>
			</div>
			<div class="info">
				<div class="v-info">—— 活动详情 ——</div>
				<div style="text-align: left;">
					<div class="title">一、详情
					</div><div class="text">1、通用流量：每月3GB国内流量，不含中国港澳台地区
					</div><div class="text">限时福利：2023年12月15日－2024年2月29日期间成功办理欢乐享用户，可额外加享20GB新春流量（流量有效期至2024年3月10日24点），领取方式见欢乐享成功订购短信。
					</div><div class="text">2、权益超市额度：每月可以兑换价值10元的热门权益或代金券
					</div><div class="text">3、多项权益：咪咕视频钻石会员+云盘空间400G+视频彩铃会员
					</div><div class="text">4、资费说明：20元/月
					</div><div class="text">5、生效时间：办理成功后均立即生效，次月起按自然月生效
					</div><div class="text">6、退订规则：办理当月可退订，退订后次月1日生效
					</div><div class="title">二、办理扣费规则
					</div><div class="text"></div><div class="text">本业务需在基础账户扣费，基础账户余额需大于20元。若余额不足，请前往中国移动10086微信公众号-我的服务-充值/中国移动客户端-充值交费，进行充值后办理。
					</div><div class="title">三、活动说明
					</div><div class="text">【活动时间】2023年10月1日-12月31日
					</div><div class="text">【办理对象】广东移动用户，以下情况无法参与本活动：
					</div><div class="text">1、日租卡、移动米粉卡、2G/3G不限量套餐客户、4G随心王套餐客户、大流量（任我用）爽卡套餐、无限量流量套餐、和多号副号、一卡双号副号、家庭服务计划副号、极光宽带副号、万能副卡等附属号码客户、集团代付/对公托收（如政企动力100行业卡）客户、集团全额统付号码、万花筒客户、手机状态异常(停机、销户、欠费、转品牌未生效等状态)号码、及其它限制办理增值业务的套餐套卡等客户。如需办理，需先主动取消以上套餐生效后方可办理。如互斥客户需要办理，要先主动取消上述互斥业务生效后方可办理。
					</div><div class="text">2、已办理10元权益超市额度/移动云盘400G融合空间月卡/视频彩铃等同类型业务无法办理，需取消后才可办理对应档次的欢乐享产品。
					</div><div class="text">3、号码状态异常、品牌转换期间，新品牌未生效期间，无法办理。
					</div><div class="text">4、已办理欢乐享产品客户无法重复办理。
					</div><div class="text">注：近期有办理主套降档的客户，暂时无法参与该活动。
					</div><div class="text">【生效规则】
					</div><div class="text">1、20元欢乐享礼包办理成功后立即生效，并发放首月流量资源，次月以月结日计算周期（全球通每月1日），即办理成功后的第一个月结日将扣取次月费用及提供次月流量；为避免您的损失，请在办理前关注您的月结周期。该产品为连续包月，办理成功后长期生效。
					</div><div class="text">2、20GB 新春流量包，限成功办理欢乐享礼包且生效起始时间在 2023年12月15日至2024年2月29日间的客户领取办理一次。同一客户号码办理多次欢乐享礼包产品的，仅可领取一次;如已经在其他渠道办理享受过该优惠，则无法再次办理。
					</div><div class="text">3、20GB 新春流量包办理立即生效，流量有效期至 2024年3月10日24点，到期自动失效。
					</div><div class="text">【退订规则】
					</div><div class="text">1、欢乐享办理当月可退订，退订后次月1日生效(彩铃包月权益立即失效)，下个月起不再扣取套餐费用和提供流量、权益超市额度、云盘空间、咪咕视频钻石会员以及视频彩铃服务。
					</div><div class="text">2、成功领取20GB新春包的客户，在新春包最少使用周期内需保持使用欢乐享产品，无法取消。
					</div><div class="text">3、20GB新春流量包最少使用周期为1个月，在最少使用周期内不支持退订。相关条款请参照中国移动通信客户业务解约条款 http://dx.10086.cn/3dHGAQ 。
					</div><div class="text">——【流量包使用规则】——
					</div><div class="text">1、欢乐享礼包中3GB流量月包为国内通用流量(适用范围不含中国港澳台地区)，首月办理成功立即生效，次月开始以月结日计算周期（全球通月结日为每月1日），未使用的流量可递延1个自然月)。
					</div><div class="text">2、欢乐享流量不支持流量转赠、流量转移、流量共享。
					</div><div class="text">3、20GB新春流量包为国内通用流量，不含中国港澳台地区。不可用于流量共享、流量转赠、流量转移和不享受流量不清零。
					</div><div class="text">——【权益超市额度使用规则】——
					</div><div class="text">1、权益超市额度价值10元/月，办理成功后每月可获得10元的权益兑换额度。
					</div><div class="text">2、权益兑换额度可用于领取超百款热门APP的会员或代金券，如视听网娱（B站、喜马拉雅等）、餐饮美食（饿了么、美团等）、出行旅游、电商购物等。领取方式点击：https://dx.10086.cn/z_UHBQ 或关注“广东移动权益中心”微信公众号，通过菜单栏-权益-权益兑换入口进入。
					</div><div class="text">3、生效规则：办理后当月额度立即生效，有效期至当月(自然月)月底最后一天。次月起，如仍使用欢乐享礼包，则每月1号10元权益额度生效，有效期至当月(自然月)月底最后一天。当月额度未使用不会累计至下月，请注意及时领取权益产品。
					</div><div class="text">4、单客户每月（自然月）可获得权益额度上限为100，超过上限额度，则当月无法再办理权益额度产品或参与权益（权益超市）优惠活动。
					</div><div class="text">5、广东移动与外部合作伙伴联合开展权益产品的推广，具体可兑换的权益以当期页面上架权益为准，权益兑换价格以当期页面显示为准，权益的兑换价格会根据第三方合作情况动态调整，期间产生的兑换差额不做补退。
					</div><div class="text">——【咪咕钻石会员使用规则】——
					</div><div class="text">1、产品权益内容：咪咕视频钻石会员月卡的权益包含全站视频免广告观看(特殊情况及政策问题无法免广告观看的情况除外)；免费观看包月类片库内的视频和新片源，点播片库购买付费视频享受折扣优惠；最高可享蓝光原画超高清视频；5个设备同时登陆。
					</div><div class="text">2、产品订购及生效规则：用户用手机号订购成功后，将向手机号所绑定的账号下发权益，即时生效。用户需打开登录咪咕视频客户端/或登陆www.miguvideo.com网站同时登录账户即可享受会员权益。
					</div><div class="text">3、因权益方内部调整，我们可能会变更、终止与权益方的合作，您可能无法继续通过中国移动接受该权益方提供产品届时您已订购的连续包月产品可通过后台帮您进行退订或退费，同时公司将会及时公告或告知。可能给您带来的不便，敬请谅解。
					</div><div class="text">4、产品有效时间核算：咪咕视频钻石会员月卡的有效时间是生效期起至次月的同日，例如:3月5日8:00生效的月会员，有效期至4月5日8:00。
					</div><div class="text">5、产品状态查看新老用户均可使用手机号验证码方式登录咪咕视频客户端，进入客户端在【我的】-【VIP会员】中可查看会员有效期。
					</div><div class="text">6、客服联系方式：客服咨询热线:10086。在线客服联系方式：咪咕视频客户端【我的】-【帮助与客服】。
					</div><div class="text">7、常见问题解答
					</div><div class="text">a.权益到账是否有短信提醒?权益到期是否有短信提醒?
					</div><div class="text">首次订购够的咪咕视频钻石会员的新用户，将收到短信。已经是咪咕视频钻石会员的，将不会收到短信提醒。权益到期,无短信提醒。
					</div><div class="text">b.相同周期/不同周期的会员权益是否支持重复订购，订购后有效期如何计算?
					</div><div class="text">可重复订购，订购后有效期将叠加，例9月1日订购了月卡，9月10日再订购月卡/季卡，权益有效期将延期到10月30日/12月31日。
					</div><div class="text">c.是否支持换绑,如何操作?
					</div><div class="text">暂不支持换账户绑定。
					</div><div class="text">——【移动云盘使用规则】——
					</div><div class="text">1、权益内容：200G个人云盘空间+200G家庭云盘空间。
					</div><div class="text">2、办理规则：全省全品牌用户可办理，不允许重复订购。
					</div><div class="text">3、生效规则：立即办理，立即生效。
					</div><div class="text">4、销户：以上会员产品业务终止服务。
					</div><div class="text">5、欠费、停机（半停）：欠费、非整月、整月停机期间都仍算在以上会员产品服务期内。
					</div><div class="text">6、换号：原号码的以上会员产品服务停止；新号码不能享受原号码的以上会员产品剩余服务。
					</div><div class="text">7、过户：过户后的用户可享用原号码的以上会员产品剩余服务。
					</div><div class="text">——【视频彩铃使用规则】——
					</div><div class="text">可以自行设定一段悦耳、多彩、有趣的个性化视频彩铃。
					</div><div class="text">1、权益内容：咪咕音乐APP不限量制作和使用DIY音频或视频彩铃，咪咕音乐官网（music.migu.cn）不限量制作和使用DIY音频彩铃，咪咕音乐全网门户免费不限量下载音频或视频彩铃。
					</div><div class="text">2、办理规则：全省全品牌用户可办理，退订当月可重复订购，一个自然月内多次开通不会重复扣费。
					</div><div class="text">3、生效规则：开通/取消后业务权益立即生效。
					</div><div class="text">4、退订规则：退订立即生效。
					</div><div class="text">——【温馨提示】——
					</div><div class="text">【超套餐资费】
					</div><div class="text">超出套餐资费按客户原标准资费计费（如有流量保障服务，按流量保障服务计费）。
					</div><div class="text">【流量封顶规则】
					</div><div class="text">1、5G套餐和全家享套餐客户：当月套外流量费达到600元时暂停上网功能，次月自动恢复；客户当月可申请打开上网功能继续使用，打开后按套外资费标准收费。
					</div><div class="text">2、非5G套餐和全家享套餐客户：可通过发送短信FD600或拨打10086申请办理单月封顶调整产品，办理后当月从原流量封顶调整为套外流量费达到600元时暂停上网功能（规则与5G和全家享套餐一致），次月恢复为原流量封顶规则。
					</div><div class="text">3、以上套餐中含达量限速、日租卡、4G CPE、5G CPE 、WAP不限量、WLAN、定向不限量流量产品、主号为达量限速的副号等产品的客户流量封顶规则不变，仍按原套餐的流量封顶规则执行。
					</div><div class="text">【流量扣减优先级】
					</div><div class="text">按现有流量扣减优先级执行。如您办理了闲时流量套餐，将优先扣取闲时流量套餐流量。
					</div><div class="text">【流量限速说明】
					</div><div class="text">在降速且未开通流量加油包的情况下，办理该流量产品，流量可以使用但仍会降低网速。如已达量限速套餐客户在限速后需办理9元3GB加油包才可解除限速。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import gdp from './gio.js'
	import axios from 'axios'
	import {send,confirm,postdemo} from '../index/api.js'
	export default {
		data: function() {
			return {
				sum:1,
				from1: '',
				code: '',
				commcode: 'GS.prod.10086000061875',
				busiSerial:'',
				sendType:false,
				num:60,
				clickid:'',
				submitStop:null
			};
		},
		async created() {
			this.clickid = this.$route.query.clickid
			let innerWidth = window.innerWidth
			this.sum = innerWidth/750
			let h = 770*this.sum
			let h1 = 500*this.sum
			document.documentElement.style.setProperty('--formTop', h + 'px');
			document.documentElement.style.setProperty('--infoTop', h1 + 'px');
			document.documentElement.style.setProperty('--p', this.sum);
		},
		mounted() {},
		methods: {
			async getCode() {
				if (this.from1) {
					let data = {
						"tel": this.from1, //手机号
						"commlist": [{
								"commcode": this.commcode
							} //商品编码
						]
					}
					if(this.sendType) return
					let m = 60
					this.num = 60
					
					let res = await send(data)
					this.$message.success('发送短信成功')
					this.busiSerial = res.data.busiSerial
					this.sendType = true
					let time = setInterval(()=>{
						m--
						this.num = m
						if(m<=0){
							clearInterval(time)
							this.sendType = false
						}
					},1000)
				}
			},
			async Submit() {
				if (!this.code) {
					alert('请输入验证码')
				}
				if (this.from1 && this.code) {
					let bodypro = {
						"busiSerial": this.busiSerial, //短信发送接口返回
						"authCode": this.code,
						"userinfo": {
							"servernum": this.from1 //手机号
						},
						"productinfo": {
							"productid": this.commcode, //产品编号
							"productgroup": "0", //产品组：默认0
							"producttype": "0", //产品类别：默认0
							"productname": "20元欢乐享", //产品名称
							"ordertype": "1" //订购类型 1-办理 2-取消 3-修改
						}
					}

					if(!this.submitStop){
						this.submitStop = true
						confirm(bodypro).then(async res=>{
							this.$message.success('办理成功!')
							if(this.clickid){
								let rel = await postdemo({clickid:this.clickid})
							}
							setTimeout(()=>{
								this.submitStop = false
							},1000)
						}).catch(err=>{
							setTimeout(()=>{
								this.submitStop = false
							},1000)
						})
					}
				}
			},
			gdp() {
				//手机号加密64
				// var base64Encoded = btoa(this.from1);

				// gdp('init','8d2279a5e2f18b7c','9d59ac736f01c688 ',{
				// 	host:'collect.gmcc.net',compress:true,
				// })
				// gdp('setUserId',base64Encoded)
				// gdp('track","bussinessProcessing",{
				// 	"goodsId_var":"55800",
				// 	"goodsName_var":"5G大流量套餐",
				// 	"skuId_var":"55800-110",
				// 	"skuName_var":"5G大流量套餐-冰神卡",
				// 	"goodsType_var":"套餐",
				// 	"processType_var":"提交订单",
				// 	"errorMessage_var":"业务互斥",
				// 	"payType_var":"支付宝",
				// 	"orderNumber_var":"12350215430",
				// 	"ordertype_var":"号卡订单",
				// 	"iop_tacticsId_var":"112233",
				// 	"referFlowId_var":"sy_1_1",
				// })
			}
		}
	}
</script>
<style lang="scss" scoped>
	.all {
		background-color: rgba(241, 69, 45, 1);
		min-height: 100vh;
		position: relative;
        overflow-y: auto;
		.imgbox {}

		.body {
			position: absolute;
			top: var(--formTop);
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.form {
				padding: 0px 16px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				p {
					color: #FFFFFF;
				}

				.input {
					width: 80%;
					height: 36px;
					padding: 0px 16px;
					border-radius: 10px;
				}

				.button {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 40px;
					width: 80%;
					background-color: rgba(191, 53, 35, 1.0);
					color: #FFFFFF;
					border-radius: 20px;
					&.null{
						background-color: #aaaaaa !important;
					}
				}
			}

			.info {
				font-size: 14px;
				padding: 0px 16px 30px;
				margin-top: var(--infoTop);
				color: #FFFFFF;
				line-height: 30px;

				.v-info {
					font-size: 20px;
				}

				.title {
					margin-top: 20px;
					font-weight: 600;
					font-size: 14px;
				}

				.tip {
					color: #ffff00;
				}

				.url {
					color: #0000ff;
				}
			}
		}
	}

	.mt10 {
		margin-top: 10px;
	}
	.row_sp {
		width: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.code {
			height: 36px;
			width: 100px;
			border-radius: 10px;
			background:rgba(241, 69, 45, 1);
			line-height: 36px;
			color: #ffffff;
		}
	}
</style>