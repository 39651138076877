import request from '@/utils/request'
/**
 * 短信发送
 */
export function send(data) {
  return request({
    url: '/get_index/send',
    method: 'post',
    data
  })
}

/**
 * 套餐办理
 * @param {object} data 请求数据
 */
export function confirm(data) {
  return request({
    url: '/get_index/confirm',
    method: 'post',
    data
  })
}

export function postdemo(data) {
  return request({
    url: '/get_index/demo',
    method: 'post',
    data
  })
}
