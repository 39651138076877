import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "tailwindcss/tailwind.css"
import ElementPlus from 'element-plus';

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import VueSocketIO from 'vue-3-socket.io';
import sockIO from "socket.io-client";

import 'element-plus/dist/index.css'
const app = createApp(App)

const baseSocket = 'ws://192.168.1.202:7001/'
app.config.globalProperties.socketApi = baseSocket
const socketio = new VueSocketIO({
  connection:sockIO(baseSocket,{
    transports:['websocket'],
    query:{
      // token:'',
      role:'user',
    },
    autoConnect:false,
  }),
  autoConnect:false,
  forceJSONP:true,
  extraHeaders:{"Access-Control-Allow-Origin":'*'}
})

app.use(store)
.use(router)
.use(socketio)
.use(ElementPlus, {zIndex: 3000 }).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}