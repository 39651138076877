<template>
	<div class="all">
		<div class="imgbox">
			<img src="@/assets/pic/bg_01.jpg" alt="">
		</div>
		<div class="body">
			<div class="form" :style="`height: ${350*sum}px;`">
				<input type="text" :style="`margin-top: ${20*sum}px;`" class="input" v-model="from1" placeholder="请输入手机号" />
				<div class="row_sp" :style="`margin-top: ${20*sum}px;`">
					<input type="text" class="input" v-model="code" placeholder="请输入验证码" style="max-width: 60%;" />
					<div class="code" v-if="!sendType" @click="getCode">获取验证码</div>
					<div class="code null" v-else>{{num}}秒</div>
				</div>
				<div class="button" @click="Submit" :class="submitStop?'null':''" :style="`margin-top: ${20*sum}px;`">立即办理</div>
			</div>
			<div class="info" :style="`margin-top: ${60*sum}px;`">
				<div class="v-info">
			        <img src="@/assets/pic/bg_03.png" alt="">
                </div>
				<div class="cont" :style="`margin-top: ${40*sum}px;`">
					<div class="box-top">
			            <img src="@/assets/pic/box_01.png" alt="">
                    </div>
					<div class="box-cont">
                        <p>名师直播课专题精选课，是面向全国中小学生及家长，以各省一线名校名师为基础，携手新东方、学而思等知名教育机构，提供覆盖小初高全学科、全学段、全学年的海量优质课程，打造与名校教学进度一致的同步课堂。</p>
                    </div>
					<div class="box-bot">
			            <img src="@/assets/pic/box_03.png" alt="">
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {send,confirm,postdemo} from '../index/api.js'
	export default {
		data: function() {
			return {
				sum:1,
				from1: '',
				code: '',
				commcode: 'GS.prod.10086000061875',
				busiSerial:'',
				sendType:false,
				num:60,
				clickid:'',
				submitStop:null
			};
		},
		async created() {
			this.clickid = this.$route.query.clickid
			let innerWidth = window.innerWidth
			this.sum = innerWidth/750
			let h = 770*this.sum
			let h1 = 500*this.sum
			document.documentElement.style.setProperty('--formTop', h + 'px');
			document.documentElement.style.setProperty('--infoTop', h1 + 'px');
			document.documentElement.style.setProperty('--p', this.sum);
		},
		mounted() {},
		methods: {
			async getCode() {
				return
				if (this.from1) {
					let data = {
						"tel": this.from1, //手机号
						"commlist": [{
								"commcode": this.commcode
							} //商品编码
						]
					}
					if(this.sendType) return
					let m = 60
					this.num = 60
					
					let res = await send(data)
					this.$message.success('发送短信成功')
					this.busiSerial = res.data.busiSerial
					this.sendType = true
					let time = setInterval(()=>{
						m--
						this.num = m
						if(m<=0){
							clearInterval(time)
							this.sendType = false
						}
					},1000)
				}
			},
			async Submit() {
				return
				if (!this.code) {
					alert('请输入验证码')
				}
				if (this.from1 && this.code) {
					let bodypro = {
						"busiSerial": this.busiSerial, //短信发送接口返回
						"authCode": this.code,
						"userinfo": {
							"servernum": this.from1 //手机号
						},
						"productinfo": {
							"productid": this.commcode, //产品编号
							"productgroup": "0", //产品组：默认0
							"producttype": "0", //产品类别：默认0
							"productname": "20元欢乐享", //产品名称
							"ordertype": "1" //订购类型 1-办理 2-取消 3-修改
						}
					}

					if(!this.submitStop){
						this.submitStop = true
						confirm(bodypro).then(async res=>{
							this.$message.success('办理成功!')
							if(this.clickid){
								let rel = await postdemo({clickid:this.clickid})
							}
							setTimeout(()=>{
								this.submitStop = false
							},1000)
						}).catch(err=>{
							setTimeout(()=>{
								this.submitStop = false
							},1000)
						})
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
    %btnClass{
        background-image: linear-gradient(#fff9c0, #ffdd6d);
        color: #d43030;
        box-shadow: inset 0px -3px 0px 0px #db9925;
    }
	.all {
		height: 100vh;
		background-color: rgb(182 219 225);
		position: relative;
        overflow-y: auto;

		.imgbox {}

		.body {
			position: absolute;
			top:45vh;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.form {
				padding: 0px 16px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				p {
					color: #FFFFFF;
				}

				.input {
					width: 85%;
					height: 40px;
					padding: 0px 16px;
					border-radius: 20px;
                    border:1px solid rgba(1,84,105,1)
				}

				.button {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 40px;
					width: 85%;
					border-radius: 20px;
                    font-weight: 800;
                    @extend %btnClass;
					&.null{
						background-color: #aaaaaa !important;
						color: #333333 !important;

					}
				}
			}

			.info {
				font-size: 14px;
				padding: 0px 16px 30px;
				line-height: 30px;
				.v-info {
                    img{
                        width:100%;
                    }
                }
                .box-cont{
                    background-image:url('/src/assets/pic/box_02.png');
                    background-size: 100% 100%;
                    padding:0 20px;
                    text-align: left;
                }
			}
		}
	}

	.mt10 {
		margin-top: 10px;
	}
    
	.row_sp {
		width: 85%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.code {
			height: 40px;
			width: 100px;
			border-radius: 20px;
            font-size:14px;
			line-height: 40px;
            @extend %btnClass
		}
	}
</style>